<template>
  <div>
    <div class="box">
      <div class="left">
        <div class="logo">
          <img src="../assets/pc/icon-logo.svg" alt="" />
        </div>
        <div class="NavMenu">
          <el-menu
            background-color="#1e1e1e"
            text-color="#fff"
            active-text-color="#ffd04b"
            :default-active="onRoutes"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            router
          >
            <el-menu-item index="home">{{ $t('tabBar').home }}</el-menu-item>
            <el-menu-item index="quotation">{{
              $t('tabBar').Quotation2
            }}</el-menu-item>
            <el-menu-item index="rapidly">{{ $t('tabBar').jsjy }}</el-menu-item>
            <!-- <el-menu-item index="contract">{{
              $t('tabBar').hyjy
            }}</el-menu-item> -->
            <el-menu-item index="trade">{{ $t('tabBar').bbjy }}</el-menu-item>
            <el-submenu index="5">
              <template slot="title">{{ $t('tabBar').jryw }}</template>
              <el-menu-item index="pledge">{{
                $t('tabBar').zyjb
              }}</el-menu-item>
            </el-submenu>
          </el-menu>
          <div class="line"></div>
        </div>
      </div>
      <div class="right">
        <div class="register" v-if="!this.Token2">
          <router-link to="/index/login">
            <button class="Sign">{{ $t('register').Login }}</button>
          </router-link>
          <router-link to="/index/register">
            <el-button type="primary" class="Sign2">{{
              $t('register').title
            }}</el-button>
          </router-link>
        </div>
        <div class="register2" v-else>
          <el-popover placement="bottom" width="200" trigger="click">
            <p>{{ $t('home').UserName }}: {{ userInfo.UserName }}</p>
            <span style="font-weight: bold">ID:{{ userInfo.Code }}</span>
            <span style="margin: 0 10px">{{
              lang === 'zh'
                ? userInfo.GradeName
                : lang === 'ty'
                ? userInfo.GradeNameThai
                : userInfo.GradeNameEnglish
            }}</span>
            <p>{{ $t('home').score }}：{{ userInfo.Credit }}</p>
            <p>{{ $t('property').zzc  }}：${{ allAmount.toFixed(2) }}</p>
            <div
              style="
                text-align: center;
                background: #66b1ff;
                border-radius: 10px;
                cursor: pointer;
                padding: 5px 0;
                margin-top: 10px;
              "
              class="btn"
              @click="Logout"
            >
              {{ $t('home').tcdl }}
            </div>
            <el-button slot="reference"
              ><img src="../assets/pc/头像.png" alt=""
            /></el-button>
          </el-popover>
        </div>
        <div class="translate">
          <el-dropdown @command="onSelect" trigger="click">
            <span class="el-dropdown-link">
              {{ $t('tabBar').qhyy
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="en">English</el-dropdown-item>
              <el-dropdown-item command="zh">简体中文</el-dropdown-item>
              <el-dropdown-item command="ty">ภาษาไทย</el-dropdown-item>
              <el-dropdown-item command="jp">日本語</el-dropdown-item>
              <el-dropdown-item command="hy">한국인</el-dropdown-item>
              <el-dropdown-item command="yn">Indonesia</el-dropdown-item>
              <el-dropdown-item command="ft">繁体中文</el-dropdown-item>
              <el-dropdown-item command="de">Deutsch</el-dropdown-item>
              <el-dropdown-item command="fr">Français</el-dropdown-item>
              <el-dropdown-item command="ar">العربية</el-dropdown-item>
              <el-dropdown-item command="ru">Русский язык</el-dropdown-item>
              <el-dropdown-item command="es">Español</el-dropdown-item>
              <el-dropdown-item command="yu">Việt Nam</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- 占位 -->
    <div class="box2"></div>
  </div>
</template>

<script>
import { Token } from '@/utils/mm'
import { EventBus } from '@/utils/event-bus'
import { mapGetters } from 'vuex'
import userApi from '@/api/user'

export default {
  name: 'CurrencyMobileNewTop',

  data() {
    return {
      Token2: Token.get(),
      activeIndex: 'home',
      checked: false,
      userInfo: {}
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection']),
    onRoutes() {
      const route = this.$route
      const { meta, path } = route
      // 在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
      // meta中 有activeMenu 字段的子页面，都会显示高亮
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    allAmount() {
      return this.LegalAmount + this.CoinAmount
    },
    LegalAmount() {
      if (this.userInfo.LegalAmountInfo) {
        const LegalAmountInfo = this.userInfo.LegalAmountInfo
        return (
          Number(LegalAmountInfo.BTCConvert) +
          Number(LegalAmountInfo.ETHConvert) +
          Number(LegalAmountInfo.USDT)
        )
      } else {
        return 0
      }
    },
    CoinAmount() {
      if (this.userInfo.CoinAmountInfo) {
        const CoinAmountInfo = this.userInfo.CoinAmountInfo
        let amount = 0
        CoinAmountInfo.forEach(item => {
          amount += Number(item.Convert)
        })
        return amount
      } else {
        return 0
      }
    }
  },

  mounted() {
    EventBus.$on('Login', (res) => {
      // console.log(res)
      this.userInfo = res
    })

    this.getUserInfo2()
    console.log(1111111111111111111111111111111, this.Token2)
  },
  methods: {
    getUserInfo2() {
      if (this.Token2) {
        this.getUserInfo()
      }
    },
    async getUserInfo() {
      this.userInfo = await userApi.userInfo()
    },

    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },

    Logout() {
      Token.remove()
      this.$router.push({ name: 'Login' })
      EventBus.$emit('Logout')
    },

    /* 双语切换 */
    onSelect(action) {
      // console.log(action)
      // this.$message('click on item ' + action)
      const lang = action
      // console.log(lang)
      this.$i18n.locale = lang
      let basicConfig = this.basicConfig
      this.$store.commit('SET_LANG', lang)
      let title = ''
      if (action === 'en') {
        title = basicConfig.CompanyName
      } else if (action === 'zh') {
        title = basicConfig.CompanyNameEnglish
      } else if (action === 'ty') {
        title = basicConfig.CompanyNameThai
      } else if (action === 'jp') {
        title = basicConfig.CompanyNameJapanese
      } else if (action === 'hy') {
        title = basicConfig.CompanyNameKorea
      } else if (action === 'yn') {
        title = basicConfig.CompanyNameIndonesia
      }
      document.title = title
      localStorage.setItem('language', lang)
      // this.$router.go(0)
      location.reload()
      EventBus.$emit('getBanner')
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #1e1e1e;
  color: #66b1ff;
}
* {
  margin: 0;
  padding: 0;
}

.el-dropdown-link {
  cursor: pointer;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown-menu {
  background-color: #1e1e1e;
  padding: 5px 12px;
  text-align: center;
  color: #fff;
}

// 设置输入框的背景色、字体颜色、边框属性设置；
/deep/.el-input__inner {
  color: #fff;
  background-color: #1e1e1e;
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: #1e1e1e;
}

.el-dropdown-menu__item {
  color: #fff;
  background-color: #1e1e1e;
}
.el-dropdown-menu__item::hover {
  color: #007aff;
  // background-color: #1e1e1e;
}

.el-menu-item {
  font-size: 14px;
  // font-size: 4px;
  margin-right: 46px;
  line-height: 60px;
  color: #fff;
}
.box2 {
  width: 100%;
  height: 60px;
}
.box {
  height: 60px;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #1e1e1e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: auto;
      min-width: 150px;
      // height: 40px;
      margin-right: 50px;
      img {
        width: 110px;
      }
    }
  }
  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .register {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-right: 10px;

      .Sign {
        font-size: 3px;
        margin-right: 10px;
        color: #fff;
        background-color: #1e1e1e;
        font-size: 16px;
      }
      .Sign2 {
        font-size: 16px;
        padding: 5px 8px;
      }
    }
    .register2 {
      margin-right: 20px;
      width: 30px;
      height: 30px;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
      .btn {
        margin: 20px auto;
        background: #007aff;
        color: #fff;
        width: 204px;
        height: 36px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .translate {
      border: 1px solid white;
      padding: 5px 15px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-dropdown-link {
        color: #fff;
      }
    }
  }
}
</style>
