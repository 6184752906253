<template>
  <div>
    <top></top>
    <router-view></router-view>
    <bottom></bottom>
  </div>
</template>

<script>
import top from '../components_pc/top'
import bottom from '../components_pc/bottom'
export default {
  name: 'CurrencyMobileNewIndex',
  components: {
    top,
    bottom
  },

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less"></style>
