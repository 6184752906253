<template>
  <div>
    <div class="box2"></div>
    <div class="box">
      <ul>
        <li>
          <!-- <img :src="this.basicConfig.LongLogo" alt="" /> -->
          <img src="../assets/pc/icon-logo.svg" alt="" />
          <p>{{ $t('pcbottom').box1 }}</p>
          <p>{{ $t('pcbottom').box2 }}</p>
          <div class="translate">
            <el-dropdown @command="onSelect" trigger="click">
              <span class="el-dropdown-link">
                {{ $t('tabBar').qhyy
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="en">English</el-dropdown-item>
              <el-dropdown-item command="zh">简体中文</el-dropdown-item>
              <el-dropdown-item command="ty">ภาษาไทย</el-dropdown-item>
              <el-dropdown-item command="jp">日本語</el-dropdown-item>
              <el-dropdown-item command="hy">한국인</el-dropdown-item>
              <el-dropdown-item command="yn">Indonesia</el-dropdown-item>
              <el-dropdown-item command="ft">繁体中文</el-dropdown-item>
              <el-dropdown-item command="de">Deutsch</el-dropdown-item>
              <el-dropdown-item command="fr">Français</el-dropdown-item>
              <el-dropdown-item command="ar">العربية</el-dropdown-item>
              <el-dropdown-item command="ru">Русский язык</el-dropdown-item>
              <el-dropdown-item command="es">Español</el-dropdown-item>
              <el-dropdown-item command="yu">Việt Nam</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </li>
        <li>
          <div class="title">{{ $t('pcbottom').box3 }}</div>
          <!-- 关于我们 -->
          <router-link to="/index/About_us?type=AboutUs">
            <p>{{ $t('pcbottom').box4 }}</p></router-link
          >
          <!-- 白皮书 -->
          <router-link to="/index/Bps?type=whitePaper">
            <p>{{ $t('pcbottom').box5 }}</p>
          </router-link>
        </li>
        <li>
          <div class="title">{{ $t('pcbottom').box6 }}</div>
          <!-- 下载 -->
          <p>
            <a :href="this.basicConfig.DownloadUrl">
              {{ $t('pcbottom').box7 }}</a
            >
          </p>
          <router-link to="/index/problem">
            <p>{{ $t('pcbottom').box8 }}</p>
          </router-link>
          <!-- 客服链接 -->
          <p>
            <a :href="this.basicConfig.ServiceUrl">{{ $t('pcbottom').box9 }}</a>
          </p>
        </li>
        <li>
          <div class="title">{{ $t('pcbottom').box10 }}</div>

          <!-- <p @click="goXY()">《{{ $t('register').xy }}》</p> -->

          <router-link to="/index/Privacy">
            <p>{{ $t('pcbottom').box12 }}</p>
          </router-link>
          <router-link to="/index/Legal">
            <p>{{ $t('pcbottom').box13 }}</p>
          </router-link>
        </li>
        <!-- <li>
          <div class="title">{{ $t('pcbottom').box14 }}</div>
          <p>https://www.bitgoldex.com</p>
          <p>support@bitgoldex.com</p>
          <p>Telegram: bitgoldex</p>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CurrencyMobileNewBottom',

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection'])
  },
  mounted() {},

  methods: {
    goXY() {
      let info = ''
      if (this.lang === 'zh') {
        info = this.basicConfig.Content
      } else if (this.lang === 'ty') {
        info = this.basicConfig.ContentThai
      } else if (this.lang === 'jp') {
        info = this.basicConfig.ContentJapanese
      } else if (this.lang === 'en') {
        info = this.basicConfig.ContentEnglish
      } else if (this.lang === 'yn') {
        info = this.basicConfig.ContentIndonesia
      } else if (this.lang === 'hy') {
        info = this.basicConfig.ContentKorea
      }
      this.$router.push({
        name: 'agreement',
        query: {
          title: this.$t('register').xy,
          info
        }
      })
    },
    /* 双语切换 */
    onSelect(action) {
      const lang = action
      this.$i18n.locale = lang
      console.log(lang)
      let basicConfig = this.basicConfig
      console.log(basicConfig)

      let title = ''
      if (action === 'en') {
        title = basicConfig.CompanyName
      } else if (action === 'zh') {
        title = basicConfig.CompanyNameEnglish
      } else if (action === 'ty') {
        title = basicConfig.CompanyNameThai
      } else if (action === 'jp') {
        title = basicConfig.CompanyNameJapanese
      } else if (action === 'hy') {
        title = basicConfig.CompanyNameKorea
      } else if (action === 'yn') {
        title = basicConfig.CompanyNameIndonesia
      }
      document.title = title
      localStorage.setItem('language', lang)
      location.reload()
      // this.$router.go(0)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #1e1e1e;
  color: #66b1ff;
}
* {
  padding: 0;
  margin: 0;
}

.el-dropdown-menu {
  background-color: #1e1e1e;
  padding: 5px 12px;
  text-align: center;
  color: #fff;
}

// 设置输入框的背景色、字体颜色、边框属性设置；
/deep/.el-input__inner {
  color: #fff;
  background-color: #1e1e1e;
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: #1e1e1e;
}

.el-dropdown-menu__item {
  color: #fff;
  background-color: #1e1e1e;
}
.el-dropdown-menu__item::hover {
  color: #007aff;
  // background-color: #1e1e1e;
}

.el-menu-item {
  font-size: 14px;
  // font-size: 4px;
  margin-right: 46px;
  line-height: 60px;
  color: #fff;
}
.translate {
  width: 150px;
  border: 1px solid white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-dropdown-link {
    color: #fff;
  }
}
.box {
  width: 100%;
  background: #1e1e1e;
  color: #fff;
  padding: 70px 90px;
  box-sizing: border-box;

  ul {
    margin: 0 auto;
    width: 80%;
    list-style: none;
    display: flex;
    justify-content: space-between;

    li {
      text-align: left;
      img {
        margin-bottom: 20px;
      }
      p {
        font-size: 14px;
        margin-bottom: 16px;
        line-height: 20px;
        display: block;
        cursor: pointer;
        color: #fff;
        a {
          color: #fff;
        }
      }

      .title {
        height: 22px;
        line-height: 22px;
        font-size: 20px;
        margin-bottom: 25px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        width: 100%;
        text-align: left;
      }

      p:hover {
        color: #3d67fa;
        cursor: pointer;
      }
      a:hover {
        color: #3d67fa;
        cursor: pointer;
      }
    }
  }
}
</style>
